import React, { useState } from "react";
import { ChevronDown, Eye } from "lucide-react";

const UpdateStatusDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    "Revised Cut Sheet Drawings Provided",
    "Room Created",
    "Drawings Submitted",
    "Packaging",
    "Completed",
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span className="truncate">{value}</span>
        <ChevronDown
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
            {options.map((option, index) => (
              <button
                key={index}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                  ${value === option ? "bg-gray-100" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const ProjectDetailsV1 = () => {
  const [updateStatuses, setUpdateStatuses] = useState({
    "01": "Revised Cut Sheet Drawings Provided",
    "02": "Packaging",
  });

  const tableData = [
    {
      id: "01",
      name: { first: "FirstName1", last: "LastName1" },
      projects: ["Project 3", "Project 1", "Project 2"],
      orderStatus: "Drawings Submitted",
      lastUpdated: "20th Jun, '24",
    },
    {
      id: "02",
      name: { first: "FirstName2", last: "LastName2" },
      projects: ["Project 5", "Project 2"],
      orderStatus: "Revised Room Details Submitted",
      lastUpdated: "20th Jun, '24",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl font-semibold mb-6">
          Administrator's Dashboard
        </h1>

        <div className="grid grid-cols-3 gap-4 mb-6">
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <div className="text-gray-600">Total Projects</div>
            <div className="text-3xl font-bold">05</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <div className="text-gray-600">Completed Orders</div>
            <div className="text-3xl font-bold">01</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <div className="text-gray-600">Active Orders</div>
            <div className="text-3xl font-bold">04</div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Customer Name
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Latest Projects
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Order Status
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Update Status
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Last Updated On
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {tableData.map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3">
                    <div>
                      <div className="font-medium">{row.name.first}</div>
                      <div className="text-gray-500">{row.name.last}</div>
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="space-y-1">
                      {row.projects.map((project, idx) => (
                        <div
                          key={idx}
                          className={`p-1 rounded ${
                            idx === 1
                              ? "bg-gray-600 text-white"
                              : "text-gray-600"
                          }`}
                        >
                          {project}
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="px-4 py-3 text-gray-600">{row.orderStatus}</td>
                  <td className="px-4 py-3 w-64">
                    <UpdateStatusDropdown
                      value={updateStatuses[row.id]}
                      onChange={(newStatus) =>
                        setUpdateStatuses((prev) => ({
                          ...prev,
                          [row.id]: newStatus,
                        }))
                      }
                    />
                  </td>
                  <td className="px-4 py-3 text-gray-600">{row.lastUpdated}</td>
                  <td className="px-4 py-3">
                    <button className="flex items-center gap-2 bg-gray-600 text-white px-3 py-2 rounded hover:bg-gray-700">
                      <Eye className="w-4 h-4" />
                      View All Projects
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ProjectDetailsV1;
