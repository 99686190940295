// furdexo-app-frontend/src/shared/layout/Layout.js
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ChevronLeft, Home } from "lucide-react";
import ProfileSettings from "../../pages/Customers/ProfileSettings";

const Layout = () => {
  const navigate = useNavigate();
  const [profileSetting, setProfileSetting] = useState(false);
  var profileDetails = localStorage.getItem('user') || '{}'
  profileDetails =JSON.parse(profileDetails);
  return (
    <>
      <div className="min-h-screen bg-gray-50">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
            <div className="flex items-center gap-4">
              <h1 className="text-xl font-medium">Furdexo</h1>
              <button
                onClick={() => navigate("/customer/enquiries")}
                className="flex items-center gap-2 px-3 py-1 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                <Home size={18} />
                Home
              </button>
              {/* Back Button next to Home */}
              <button
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 px-3 py-1 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                <ChevronLeft size={18} />
                Back
              </button>
            </div>
            <div className="flex items-center gap-4">
              {profileDetails && profileDetails.role === 1 && (
                <div>
                  <button
                    onClick={() => navigate("/admin/dashboard")}
                    className="flex items-center gap-2 px-3 py-1 bg-gray-700 text-white rounded-md hover:bg-gray-800"
                  >
                    Administrator
                  </button>
                </div>
              )}
              <div>
                Welcome back, {profileDetails.firstName}{" "}
                {profileDetails.lastName}!
              </div>
              <button
                onClick={() => setProfileSetting(true)}
                className="w-8 h-8 bg-gray-200 rounded-full"
              />
            </div>
          </div>
        </header>
        <Outlet />
        {/* <footer className="bg-white shadow mt-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <p className="text-center text-gray-500 text-sm">
              © {new Date().getFullYear()} Furdexo. All rights reserved.
            </p>
          </div>
        </footer> */}
        <ProfileSettings
          profileDetails={profileDetails}
          isOpen={profileSetting}
          onClose={() => setProfileSetting(false)}
        />
      </div>
    </>
  );
};

export default Layout;
