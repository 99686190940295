import React from "react";
import { Routes } from "react-router-dom";
import { HomeRoutes } from "./routes";
import './utils/interceptor'

const App = () => {
  return (
    <div className="App">
      <Routes>{HomeRoutes}</Routes>
    </div>
  );
};

export default App;