// furdexo-app-frontend/src/action/spaceActions.js
import axios from "axios";
import { urlProjects, urlSpaces } from "../config/url";

export async function spaceRegistration(dispatch, projectId, payload) {
  try {    
    const response = await axios.post(`${urlSpaces}/${projectId}/spaces`, payload);
    dispatch({ type: "SPACE_REGISTER_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
    console.log(error);
  }
}


export async function getSpaces(dispatch, projectId) {
  try {
    const response = await axios.get(`${urlSpaces}/${projectId}/spaces`);
    dispatch({
      type: "SPACE_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
  }
};


export async function getSpaceById(dispatch, projectId, spaceId) {
  try {
    const response = await axios.get(
      `${urlSpaces}/${projectId}/spaces/${spaceId}`
    );
    dispatch({
      type: "SPACE_DETAILS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
  }
};

export async function deleteSpaceById(dispatch, projectId, spaceId) {
  try {
    const response = await axios.put(
      `${urlSpaces}/${projectId}/spaces/${spaceId}`
    );
    dispatch({
      type: "SPACE_DETAILS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
  }
};

export async function updateSpaceByProjectId(dispatch, projectId, payload) {
  try {
    const response = await axios.put(
      `${urlSpaces}/${projectId}/spaces`,
      payload
    );
    dispatch({
      type: "SPACE_DETAILS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response,
    });
  }
};

export async function updateSpaceStatusBySpaceId(dispatch, projectId, spaceId, payload) {
  try {
    const response = await axios.put(
      `${urlSpaces}/${projectId}/space/${spaceId}`,
      payload
    );
    dispatch({
      type: "SPACE_UPDATE_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
  }
};

export async function getDrawingPreviewBySpaceIdByDrawingId(
  dispatch,
  projectId,
  spaceId,
  drawingId
) {
  try {
    const response = await axios.get(
      `${urlProjects}/${projectId}/spaces/${spaceId}/drawings/${drawingId}`);
    dispatch({
      type: "PREVIEW_DRAWING_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({
      type: "SPACE_ERROR",
      apiResponse: error.response.data,
    });
  }
};
