// src/pages/Admin/FurdexoUploads.js
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { getSpaces } from "../../actions/spaceActions";
import { Plus } from "lucide-react";
import { urlProjects } from "../../config/url";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../config/url";

const DrawingThumbnail = ({ drawing, setDrawingSelected, drawingSelected }) => {
  // console.log("====drawing", drawing, drawingSelected);
  return (
    <div
      onClick={() =>
        setDrawingSelected({
          path: `${API_BASE_URL}/${drawing.path}`,
          originalName: drawing.originalName,
          _id: drawing._id,
        })
      }
      className={`relative rounded-lg overflow-hidden cursor-pointer border-2 
      ${
        drawing._id === drawingSelected._id
          ? "border-gray-700"
          : "border-gray-200"
      }`}
    >
      <div
        className={`p-2 text-center ${
          drawing._id === drawingSelected._id
            ? "bg-gray-700 text-white"
            : "bg-gray-200"
        }`}
      >
        {drawing?.originalName}
      </div>
    </div>
  );
};

const FurdexoUploads = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [spaceId, setSpaceId] = useState();
  const { spaces, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );
  const [drawingSelected, setDrawingSelected] = useState({
    path: "",
    originalName: "",
    _id: "",
  });

  const handleGetSpace = async () => {
    await getSpaces(dispatch, projectId);
  };

  useEffect(() => {
    handleGetSpace();
  }, [projectId]);

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("drawings", file);
      });
      formData.append("uploadedBy", "furdexo");

      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${spaceId}/drawings/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      toast.success(data?.message, { position: "bottom-center" });
      handleGetSpace();
    } catch (error) {
      console.error("Upload error:", error);
      // Handle error
    } finally {
      setIsUploading(false);
    }
  };

  const downloadPdf = (pdfUrl, downloadName) => {
    if (!pdfUrl) {
      toast.error("Please select drawing to download");
      return;
    }
    saveAs(pdfUrl, downloadName);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="h-full flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold mb-6">Furdexo Uploads</h1>
        </div>
        <div className="h-full flex items-center">
          <div
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            {`${drawingSelected?.path}` ? (
              <a href={`${drawingSelected?.path}`} target="_blank">
                View PDF
              </a>
            ) : (
              <span
                onClick={() => toast.error("Please select drawing to download")}
              >
                View PDF
              </span>
            )}
          </div>
          <div
            onClick={() =>
              downloadPdf(
                `${drawingSelected?.path}`,
                `${drawingSelected?.originalName}`
              )
            }
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            Download PDF
          </div>
        </div>
      </div>

      {spaces.length > 0 ? (
        <div className="space-y-4">
          {spaces.map((space) => (
            <div key={space._id} className="bg-white shadow-sm p-4 rounded-lg">
              <div className="flex items-start gap-4 mb-4">
                <div className="font-medium">{space?.name}</div>
                <div className="flex-1 flex justify-end gap-2">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    multiple
                    accept=".pdf,.dwg,.dxf"
                    className="hidden"
                  />
                  <button
                    disabled={isUploading}
                    onClick={() => {
                      setSpaceId(space._id);
                      fileInputRef.current?.click();
                    }}
                    className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 flex items-center gap-2"
                  >
                    <Plus size={18} />
                    {isUploading ? "Uploading..." : "Add New Drawing"}
                  </button>
                </div>
              </div>
              <div className="flex items-start gap-4 mb-4">
                <div className="flex gap-2">
                  {space.uploads?.length > 0 &&
                    space.uploads
                      ?.filter((drawing) => drawing?.uploadedBy === "furdexo")
                      .map((drawing, index) => (
                        <DrawingThumbnail
                          key={drawing._id}
                          drawing={drawing}
                          name={drawing.originalName}
                          setDrawingSelected={setDrawingSelected}
                          drawingSelected={drawingSelected}
                        />
                      ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No Furdexo upload found.</div>
      )}
    </div>
  );
};

export default FurdexoUploads;
