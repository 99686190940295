const initialState = {
  spaces: [],
  space: null,
  previewDrawing: null,
  apiSpace: null,
  apiResponse: null,
  apiLoading: true,
  apiSuccess: true,
};

export const spaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SPACE_DETAILS_FETCH_SUCCESS":
      return { ...state, space: action.apiResponse, apiLoading: false };
    case "SPACE_FETCH_SUCCESS":
      return { ...state, spaces: action.apiResponse, apiLoading: false };
    case "SPACE_UPDATE_SUCCESS":
      return { ...state, space: action.apiResponse, apiLoading: false };
    case "PREVIEW_DRAWING_SUCCESS":
      return {
        ...state,
        previewDrawing: action.apiResponse,
        apiLoading: false,
      };
    case "SPACE_REGISTER_SUCCESS":
      return {
        ...state,
        apiSpace: action.apiResponse.results,
        apiLoading: false,
        error: null,
      };
    case "SPACE_ERROR":
      return { ...state, error: action.error, apiLoading: false };
    default:
      return state;
  }
};
