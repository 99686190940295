import React, { useState, useEffect } from 'react';
import { X, Loader } from 'lucide-react';

// File Card Component
const FileCard = ({ name, version }) => (
  <div className="relative bg-white rounded-lg border border-gray-200">
    {version && (
      <div className="absolute right-2 top-2 bg-gray-700 text-white px-2 py-0.5 text-xs rounded">
        v{version}
      </div>
    )}
    <div className="aspect-square p-4">
      <div className="w-full h-full flex items-center justify-center">
        <svg className="w-16 h-16 text-gray-300" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M4 4h16v16H4z M4 4l8 8 8-8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
    </div>
    <div className="bg-gray-700 text-white py-2 px-4 text-center text-sm rounded-b-lg">
      {name}
    </div>
  </div>
);

const UploadFiles = ({ project, onClose, onFileUpload }) => {
  const [files, setFiles] = useState({
    customerUploads: [],
    furdexoUploads: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetch(`http://localhost:5000/api/customers/projects/${project._id}/files`);
        if (!response.ok) throw new Error('Failed to fetch files');
        const data = await response.json();
        setFiles({
          customerUploads: data.customerUploads || [],
          furdexoUploads: data.furdexoUploads || []
        });
      } catch (err) {
        console.error('Error fetching files:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (project?._id) {
      fetchFiles();
    }
  }, [project]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      alert('Please upload only PDF files.');
      return;
    }

    try {
      setUploading(true);
      setError(null);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('version', '1');
      
      const response = await fetch(`http://localhost:5000/api/customers/projects/${project._id}/upload/furdexo`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('Failed to upload file');
      
      onFileUpload?.();
      
      const updatedResponse = await fetch(`http://localhost:5000/api/customers/projects/${project._id}/files`);
      const updatedData = await updatedResponse.json();
      setFiles({
        customerUploads: updatedData.customerUploads || [],
        furdexoUploads: updatedData.furdexoUploads || []
      });
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Loading State
  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 flex items-center space-x-4">
          <Loader className="h-6 w-6 text-gray-600 animate-spin" />
          <p className="text-gray-600">Loading files...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-[800px] max-w-[95vw] mx-4">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Uploaded Files</h3>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="p-4 bg-red-50 border-l-4 border-red-500">
            <div className="flex">
              <div className="flex-1">
                <p className="text-sm text-red-700">
                  Error: {error}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Modal Content */}
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Customer Uploads Section */}
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Customer Uploads</h4>
              <div className="grid grid-cols-2 gap-4">
                {files.customerUploads.length > 0 ? (
                  files.customerUploads.map((file, index) => (
                    <FileCard
                      key={file._id || index}
                      name={file.name}
                      version={file.version}
                    />
                  ))
                ) : (
                  <div className="col-span-2 py-8 text-center text-gray-500">
                    No customer uploads yet
                  </div>
                )}
              </div>
            </div>

            {/* Furdexo Uploads Section */}
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Furdexo Uploads</h4>
              <div className="grid grid-cols-2 gap-4">
                {files.furdexoUploads.length > 0 ? (
                  files.furdexoUploads.map((file, index) => (
                    <FileCard
                      key={file._id || index}
                      name={file.name}
                      version={file.version}
                    />
                  ))
                ) : (
                  <div className="col-span-2 py-8 text-center text-gray-500">
                    No Furdexo uploads yet
                  </div>
                )}
              </div>
              
              {/* Upload Button */}
              <div className="mt-6">
                <input
                  type="file"
                  id="bomUpload"
                  accept=".pdf"
                  className="hidden"
                  onChange={handleFileUpload}
                  disabled={uploading}
                />
                <label
                  htmlFor="bomUpload"
                  className={`block w-full text-center bg-gray-700 text-white text-sm font-medium px-4 py-2 rounded cursor-pointer hover:bg-gray-600 transition-colors ${
                    uploading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {uploading ? (
                    <span className="flex items-center justify-center">
                      <Loader className="h-4 w-4 animate-spin mr-2" />
                      Uploading...
                    </span>
                  ) : (
                    'Add New BoM (.pdf)'
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;