// furdexo-app-frontend/src/pages/customer/ProfileSettings.js

import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const ProfileSettings = ({ profileDetails, isOpen, onClose }) => {
  const [formData, setFormData] = useState(profileDetails);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);

  const showMessage = (text, type = "success") => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 3000);
  };

  const validateForm = () => {
    const newErrors = {};

    // Company validations
    if (!formData.name.trim()) {
      newErrors.companyName = "Company name is required";
    }

    // Profile validations
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (formData.password || formData.confirmPassword) {
      if (formData.password.length < 6) {
        newErrors.password = "Password must be at least 6 characters";
      }
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) {
      showMessage("Please fix the errors before submitting", "error");
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch("/api/profile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyName: formData?.name,
          websiteLink: formData?.websiteLink,
          gstin1: formData?.gstin1,
          gstin2: formData?.gstin2,
          gstin3: formData?.gstin3,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          whatsappNo: formData?.whatsappNo,
          email: formData?.email,
          ...(formData?.password && {
            password: formData?.password,
          }),
        }),
      });

      if (response.ok) {
        showMessage("Profile updated successfully");
        onClose();
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      showMessage("Failed to update profile", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 rounded-lg p-6 w-full max-w-5xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">Profile Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Company Info */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Company Info</h3>

              <div>
                <input
                  type="text"
                  placeholder="Company Name"
                  value={formData?.companyName}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      company: { ...prev.company, name: e.target.value },
                    }))
                  }
                  className={`w-full px-4 py-2 bg-white border rounded-md
                    ${
                      errors.companyName ? "border-red-500" : "border-gray-200"
                    }`}
                />
                {errors.companyName && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.companyName}
                  </p>
                )}
              </div>

              <input
                type="url"
                placeholder="Website Link"
                value={formData?.websiteLink}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    company: { ...prev.company, websiteLink: e.target.value },
                  }))
                }
                className="w-full px-4 py-2 bg-white border border-gray-200 rounded-md"
              />

              {["gstin1", "gstin2", "gstin3"].map((gstin, index) => (
                <input
                  key={gstin}
                  type="text"
                  placeholder={`GSTIN ${index + 1}`}
                  value={formData?.gstin}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      company: { ...prev.company, [gstin]: e.target.value },
                    }))
                  }
                  className="w-full px-4 py-2 bg-white border border-gray-200 rounded-md"
                />
              ))}
            </div>

            {/* Profile Info */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Your Profile</h3>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={formData?.firstName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        profile: { ...prev.profile, firstName: e.target.value },
                      }))
                    }
                    className={`w-full px-4 py-2 bg-white border rounded-md
                      ${
                        errors.firstName ? "border-red-500" : "border-gray-200"
                      }`}
                  />
                  {errors.firstName && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                <div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={formData?.lastName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        profile: { ...prev.profile, lastName: e.target.value },
                      }))
                    }
                    className={`w-full px-4 py-2 bg-white border rounded-md
                      ${
                        errors.lastName ? "border-red-500" : "border-gray-200"
                      }`}
                  />
                  {errors.lastName && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex">
                <span className="px-4 py-2 bg-white border border-r-0 border-gray-200 rounded-l-md text-gray-500">
                  +91
                </span>
                <input
                  type="tel"
                  placeholder="Whatsapp No."
                  value={formData?.whatsappNo}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      profile: { ...prev.profile, whatsappNo: e.target.value },
                    }))
                  }
                  className="flex-1 px-4 py-2 bg-white border border-gray-200 rounded-r-md"
                />
              </div>

              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      profile: { ...prev.profile, email: e.target.value },
                    }))
                  }
                  className={`w-full px-4 py-2 bg-white border rounded-md
                    ${errors.email ? "border-red-500" : "border-gray-200"}`}
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                )}
              </div>

              <div>
                <input
                  type="password"
                  placeholder="Password"
                  value={formData?.password}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      profile: { ...prev.profile, password: e.target.value },
                    }))
                  }
                  className={`w-full px-4 py-2 bg-white border rounded-md
                    ${errors.password ? "border-red-500" : "border-gray-200"}`}
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-500">{errors.password}</p>
                )}
              </div>

              <div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={formData?.confirmPassword}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      profile: {
                        ...prev.profile,
                        confirmPassword: e.target.value,
                      },
                    }))
                  }
                  className={`w-full px-4 py-2 bg-white border rounded-md
                    ${
                      errors.confirmPassword
                        ? "border-red-500"
                        : "border-gray-200"
                    }`}
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-sm text-red-500">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800 
              disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? "Saving..." : "Save Settings"}
          </button>
        </form>

        {message && (
          <div
            className={`fixed bottom-4 right-4 px-4 py-2 rounded-lg text-white
            ${message.type === "success" ? "bg-green-600" : "bg-red-600"}`}
          >
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
