// client/src/components/Order_Details.js

import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Tag, ChevronUp, ChevronDown, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsById } from "../../actions/projectActions";
import { getUserDetails } from "../../actions/userActions";
import {
  getSpaces,
  updateSpaceById,
  updateSpaceByProjectId,
} from "../../actions/spaceActions";
import ChatBot from "../../components/ChatBot/ChatBot";
import Processing from "../../components/Processing/Processing";

const UpdateStatusDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    // "Revised Cut Sheet Drawings Provided",
    "RFT",
    "RMT",
    "SQFT",
    "SQMT",
    "Nos.",
    "Packing",
    "LS",
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span className="truncate">{value}</span>
        <ChevronDown
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="flex flex-col max-h-[30vh] overflow-y-auto flex-grow absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
            {options.map((option, index) => (
              <button
                key={index}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                  ${value === option ? "bg-gray-100" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const AddEstimationRow = ({ isOpen, onClose, orderDetails, spaceAddRow }) => {
  const [formData, setFormData] = useState({
    category: "",
    label: "",
    rate: 0,
    quantity: 0,
    unit: "",
  });
  if (!isOpen) return null;

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]:
        field === "label" || field === "category" || field === "unit"
          ? value
          : parseFloat(value),
    }));
  };
  const handleAddRow = () => {
    console.log("====formData", formData, spaceAddRow);
    let spaces = orderDetails.spaces || [];
    console.log("=====spaces", spaces);
    for (var i = 0; i < spaces.length; i++) {
      let space = spaces[i];
      if (spaceAddRow.spaceId === space._id) {
        let items = space.items || [];
        items.push({
          ...formData,
          _id: Date.now().toString(36),
          isRowSelected: true,
        });
        console.log("=====items", items);
      }
    }
    onClose(false);
    setFormData({
      category: "",
      label: "",
      rate: 0,
      quantity: 0,
      unit: "",
    });
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg w-[800px] flex flex-col max-h-[90vh]">
          {/* Fixed Header */}
          <div className="flex justify-between items-center p-6 border-b shrink-0">
            <h2 className="text-xl font-medium">Add Estimation Row</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="overflow-y-auto flex-grow">
            <div className="p-6">
              <div className="grid grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-4">
                  <div>
                    <label>Category</label>
                    <input
                      type="text"
                      placeholder="Enter Category"
                      value={formData.category}
                      onChange={(e) =>
                        handleInputChange("category", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label style={{ paddingTop: "2px !important" }}>
                      Detail
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Detail"
                      value={formData.label}
                      onChange={(e) =>
                        handleInputChange("label", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label>Unit Of Measurement</label>
                    <UpdateStatusDropdown
                      value={formData.unit}
                      onChange={(newUnit) => handleInputChange("unit", newUnit)}
                    />
                  </div>
                  <div>
                    <label>Rate Per Unit</label>
                    <input
                      type="number"
                      placeholder="Enter Rate Per Unit"
                      value={formData.rate}
                      onChange={(e) =>
                        handleInputChange("rate", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label>Quantity</label>
                    <input
                      type="number"
                      placeholder="Enter Quantity"
                      value={formData.quantity}
                      onChange={(e) =>
                        handleInputChange("quantity", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="border-t p-6 flex justify-end shrink-0">
            <button
              onClick={handleAddRow}
              className="bg-gray-700 text-white px-6 py-2 rounded text-sm"
            >
              Add New Estimate Item
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemRow = ({
  isRowSelected,
  currentStatus,
  item,
  detail,
  unit,
  quantity,
  rate,
  price,
  onQuantityChange,
  onRowSelectionChange,
  isRoomSelected,
}) => (
  <tr className="border-t border-gray-200 hover:bg-gray-50">
    <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      <input
        type="checkbox"
        checked={isRoomSelected || isRowSelected}
        onChange={(e) => onRowSelectionChange(e.target.checked)}
      />
    </td>
    <td className="px-6 py-4 text-sm text-gray-900">{item}</td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <span className="text-sm text-gray-900">{detail}</span>
        <ChevronDown className="ml-2 h-4 w-4 text-gray-400 cursor-pointer" />
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <span className="text-sm text-gray-900">{unit}</span>
        {/* <ChevronDown className="ml-2 h-4 w-4 text-gray-400 cursor-pointer" /> */}
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center justify-center space-x-4">
        {/* <button
          disabled={currentStatus === "Order Placed"}
          onClick={() => onQuantityChange(Math.max(0, quantity - 1))}
          className="text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <ChevronDown className="h-4 w-4" />
        </button> */}
        <input
          type="number"
          disabled={currentStatus === "Order Placed"}
          value={quantity}
          onChange={(e) => onQuantityChange(e.target.value)}
          className="text-center text-sm text-gray-900 bg-transparent border rounded-md px-2 py-1"
        />
        {/* <button
          disabled={currentStatus === "Order Placed"}
          onClick={() => onQuantityChange(quantity + 1)}
          className="text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <ChevronUp className="h-4 w-4" />
        </button> */}
      </div>
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      ₹
      {rate
        ? rate.toLocaleString("en-IN", { minimumFractionDigits: 2 })
        : "0.00"}
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      ₹
      {price
        ? price.toLocaleString("en-IN", { minimumFractionDigits: 2 })
        : "0.00"}
    </td>
  </tr>
);

const RoomSection = ({
  space,
  estimate,
  items,
  onQuantityChange,
  onRowSelectionChange,
  onRoomSelectionChange,
  setIsCreateNewEstimateModal,
  setSpaceAddRow,
}) => (
  <>
    <tr className="bg-gray-100">
      <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        <input
          type="checkbox"
          // indeterminate={true}
          checked={space?.isRoomSelected}
          onChange={(e) => onRoomSelectionChange(e.target.checked)}
        />
      </td>
      <td colSpan="6">
        <div className="flex justify-between px-6 py-3 border-b border-gray-200">
          <span className="text-sm font-medium text-gray-700">
            {space?.name}
          </span>
          {space?.currentStatus === "Order Placed" && (
            <span className="text-sm font-medium text-red-700">
              {space?.currentStatus}
            </span>
          )}
          <span className="text-sm text-gray-500">
            Room Estimate: ₹
            {estimate.toLocaleString("en-IN", { minimumFractionDigits: 2 })}
          </span>
        </div>
      </td>
    </tr>
    {items?.map(
      (item, index) =>
        item.label !== "Other" && (
          <ItemRow
            key={index}
            isRowSelected={item?.isRowSelected}
            currentStatus={space?.currentStatus}
            item={item.category}
            detail={item.label}
            unit={item.unit}
            quantity={item.quantity}
            rate={item.rate}
            price={item.quantity * item.rate}
            onQuantityChange={(newQuantity) =>
              onQuantityChange(index, newQuantity)
            }
            onRowSelectionChange={(isRowSelected) =>
              onRowSelectionChange(index, isRowSelected)
            }
            isRoomSelected={space?.isRoomSelected}
          />
        )
    )}
    <tr className="bg-gray-100">
      <td colSpan="7" className="px-6 py-4" style={{ textAlign: "center" }}>
        <button
          onClick={() => {
            setIsCreateNewEstimateModal(true);
            setSpaceAddRow({ spaceId: space._id });
          }}
          className="bg-gray-700 text-white px-4 py-2 rounded"
        >
          Add Row
        </button>
      </td>
    </tr>
  </>
);

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { customerId, projectId } = useParams();
  const [isCreateNewEstimateModal, setIsCreateNewEstimateModal] =
    useState(false);
  const [spaceAddRow, setSpaceAddRow] = useState({
    spaceId: "",
    category: "",
  });
  const [showProcessing, setShowProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    spaces: [],
    totalEstimate: 0,
    totalRooms: 0,
    name: "",
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { project } = useSelector((state) => state.project);
  const { user } = useSelector((state) => state.user);
  const { spaces, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );
  const fetchUserDetails = async () => {
    await getUserDetails(dispatch, customerId);
  };
  const fetchProjectById = async () => {
    await getProjectsById(dispatch, projectId);
  };
  const handleGetSpace = async () => {
    await getSpaces(dispatch, projectId);
  };

  useEffect(() => {
    fetchUserDetails();
    fetchProjectById();
    handleGetSpace();
  }, [projectId, customerId]);

  useEffect(() => {
    const processedRooms = spaces.map((space) => ({
      ...space,
      items: space.spaceSettings,
    }));
    const processedRoom = processedRooms.map((room) => ({
      ...room,
      estimate: calculateRoomEstimate(room.items),
    }));

    setOrderDetails({
      spaces: processedRoom,
      totalEstimate: processedRoom.reduce(
        (sum, room) => sum + (room.isRowSelected ? room.estimate : 0.0),
        0
      ),
      totalRooms: spaces.length,
    });
  }, [projectId, spaces]);

  //  console.log("orderDetails", orderDetails);

  const calculateRoomEstimate = useCallback((items) => {
    return items.reduce(
      (sum, item) =>
        sum +
        (item.isRowSelected
          ? item.quantity * (item?.rate ? item.rate : 0)
          : 0.0),
      0
    );
  }, []);

  const handleQuantityChange = useCallback(
    (roomIndex, itemIndex, newQuantity) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
        };
        newRooms[roomIndex].items[itemIndex] = {
          ...newRooms[roomIndex].items[itemIndex],
          quantity: newQuantity,
        };

        // Update room estimate
        newRooms[roomIndex].estimate = calculateRoomEstimate(
          newRooms[roomIndex].items
        );

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );

        return {
          ...prev,
          spaces: newRooms,
          totalEstimate,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleRowSelectionChange = useCallback(
    (roomIndex, itemIndex, isRowSelected) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
        };
        if (!isRowSelected) {
          newRooms[roomIndex]["isRoomSelected"] = false;
          prev["isAllRoomSelected"] = false;
        }
        newRooms[roomIndex].items[itemIndex] = {
          ...newRooms[roomIndex].items[itemIndex],
          isRowSelected: isRowSelected,
        };

        const totalItemsSelected = newRooms[roomIndex].items.reduce(
          (sum, item) => sum + (item.isRowSelected ? 1 : 0),
          0
        );

        if (
          newRooms[roomIndex].items.length === totalItemsSelected
        ) {
          newRooms[roomIndex]["isRoomSelected"] = true;
        }

        const totalRoomSelected = newRooms.reduce(
          (sum, room) => sum + (room.isRoomSelected ? 1 : 0),
          0
        );

        if (newRooms.length === totalRoomSelected) {
          prev["isAllRoomSelected"] = true;
        }

        // Update room estimate
        newRooms[roomIndex].estimate = calculateRoomEstimate(
          newRooms[roomIndex].items
        );

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );

        return {
          ...prev,
          spaces: newRooms,
          totalEstimate,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleRoomSelectionChange = useCallback(
    (roomIndex, isRoomSelected) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
          isRoomSelected: isRoomSelected,
        };
        newRooms[roomIndex].items = newRooms[roomIndex].items.map((item) => ({
          ...item,
          isRowSelected: isRoomSelected,
        }));

        const totalRoomSelected = newRooms.reduce(
          (sum, room) => (sum + (room.isRoomSelected ? 1 : 0)),
          0
        );
        if(newRooms.length === totalRoomSelected){
          prev["isAllRoomSelected"] = true;
        }
        else if (!isRoomSelected) {
          prev["isAllRoomSelected"] = false;
        }

        // Update room estimate
        newRooms[roomIndex].estimate = isRoomSelected
          ? calculateRoomEstimate(newRooms[roomIndex].items)
          : 0.0;

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );

        return {
          ...prev,
          spaces: newRooms,
          totalEstimate,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleAllCategorySelectionChange = useCallback(
    (isAllRoomSelected) => {
      setOrderDetails((prev) => {
        var newRooms = [...prev.spaces];

        newRooms = newRooms.map((room) => ({
          ...room,
          isRoomSelected: isAllRoomSelected,
          items: room.items.map((item) => ({
            ...item,
            isRowSelected: isAllRoomSelected,
          })),
        }));

        newRooms = newRooms.map((room) => ({
          ...room,
          estimate: isAllRoomSelected
            ? calculateRoomEstimate(room.items)
            : 0.0,
        }));

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );

        return {
          ...prev,
          isAllRoomSelected,
          spaces: newRooms,
          totalEstimate,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleSubmitReview = async () => {
    setShowProcessing(true);
    var updataPayload = [];
    for (var i = 0; i < orderDetails.spaces.length; i++) {
      let space = orderDetails.spaces[i];
      updataPayload.push({
        spaceId: space._id,
        spaceSettings: space.items,
        currentStatus: "Estimate Generated",
      });
    }
    console.log("updataPayload:", projectId, updataPayload);
    await updateSpaceByProjectId(dispatch, projectId, {
      datas: updataPayload,
    });
    setShowProcessing(false);
  };
  console.log("=====setSpaceAddRow", spaceAddRow);
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex justify-between items-center mb-4">
          {/* Breadcrumb */}
          <div className="text-lg">
            <Link to="/admin" className="text-gray-600 hover:text-gray-800">
              Administrator's Dashboard
            </Link>
            <span className="mx-2 text-gray-400">/</span>
            <Link
              to={`/admin/customer/${customerId}`}
              className="text-gray-600 hover:text-gray-800"
            >
              {user?.firstName} {user?.lastName}'s Orders
            </Link>
            <span className="mx-2 text-gray-400">/</span>
            <span className="text-gray-900 font-medium">
              {project?.projectName}
            </span>
          </div>
          {/* Rooms Count */}
          <div className="text-right">
            <div className="text-gray-600">Rooms</div>
            <div className="text-2xl font-bold text-gray-900">
              {String(spaces.length).padStart(2, "0")}
            </div>
          </div>
        </div>

        {/* Total Estimate */}
        <div className="bg-white shadow-sm rounded-lg p-4 mb-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <Tag className="h-5 w-5 text-gray-500" />
              <span className="text-lg font-medium text-gray-900">
                PRICE PER ITEM
              </span>
            </div>
            {orderDetails?.spaces && orderDetails.spaces.length > 0 && (
              <button
                onClick={handleSubmitReview}
                className="bg-gray-700 text-white px-4 py-2 rounded"
              >
                Generate Estimate
              </button>
            )}
            <div className="text-right">
              <div className="text-gray-600">Total Estimate</div>
              <div className="text-2xl font-bold text-gray-900">
                ₹
                {orderDetails.totalEstimate.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                })}
              </div>
              <ChatBot
                selectedProject={project}
                isChatOpen={isChatOpen}
                setIsChatOpen={setIsChatOpen}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <table className="min-w-full">
            <thead className="bg-gray-50 border-b border-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    checked={orderDetails.isAllRoomSelected}
                    onChange={(e) =>
                      handleAllCategorySelectionChange(e.target.checked)
                    }
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Detail
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Unit of Measurement
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rate / Unit
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Price
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orderDetails.spaces.map((space, spaceIndex) => (
                <RoomSection
                  key={space._id || spaceIndex}
                  space={space}
                  estimate={space.estimate}
                  items={space.items}
                  onQuantityChange={(itemIndex, newQuantity) =>
                    handleQuantityChange(spaceIndex, itemIndex, newQuantity)
                  }
                  onRowSelectionChange={(itemIndex, isRowSelected) =>
                    handleRowSelectionChange(
                      spaceIndex,
                      itemIndex,
                      isRowSelected
                    )
                  }
                  onRoomSelectionChange={(isRoomSelected) =>
                    handleRoomSelectionChange(spaceIndex, isRoomSelected)
                  }
                  setIsCreateNewEstimateModal={setIsCreateNewEstimateModal}
                  setSpaceAddRow={setSpaceAddRow}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Processing show={showProcessing} />
      <AddEstimationRow
        isOpen={isCreateNewEstimateModal}
        onClose={() => setIsCreateNewEstimateModal(false)}
        orderDetails={orderDetails}
        spaceAddRow={spaceAddRow}
      />
    </div>
  );
};

export default OrderDetails;
