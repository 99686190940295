// furdexo-app-frontend/src/reducers/projectReducers.js
const initialState = {
  projects: [],
  project: null,
  apiResponse: null,
  apiLoading: true,
  apiSuccess: true,
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROJECTS_FETCH_SUCCESS":
      return { ...state, projects: action.apiResponse, loading: false };
    case "PROJECT_FETCH_SUCCESS":
      return { ...state, project: action.apiResponse, loading: false };
    case "PROJECT_UPADTE_SUCCESS":
      return { ...state, project: action.apiResponse, loading: false };
    case "PROJECT_REGISTER_SUCCESS":
      return { ...state, project: action.payload, loading: false, error: null };
    case "PROJECT_ERROR":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
