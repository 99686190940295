import { Navigate, Route } from "react-router-dom";
import Layout from "./shared/layout/Layout";
import CustomerRegistration from "./pages/Customers/CustomerRegistration";
// import AdminDashboard from "./components/AdminDashboard";
// import CustomerInfo from "./components/CustomerInfo";
import CustomerEnquiry from "./pages/Customers/CustomerEnquiry";
import ProjectDetails from "./pages/Customers/ProjectDetails";
import ProfileSettings from "./pages/Customers/ProfileSettings";
import DrawingViewer from "./pages/Customers/DrawingViewer";
import ProjectDetailsV1 from "./pages/Customers/ProjectDetailsV1";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Login from "./components/Auth/Login";
import CustomerInfo from "./pages/Admin/CustomerInfo";
import OrderDetails from "./pages/Admin/OrderDetails";
import CustomerUploads from "./pages/Admin/CustomerUploads";
import FurdexoUploads from "./pages/Admin/FurdexoUploads";
import ViewFurdexoUploads from "./pages/Customers/ViewFurdexoUploads";

export const HomeRoutes = (
  <>
    {/* <Route path="/" element={<Layout />}>
      <Route path="/" element={<Navigate to="/admin" replace />} />
      <Route path="admin" element={<AdminDashboard />} />
    </Route> */}
    <Route path="admin" element={<Layout />}>
      <Route path="" element={<Navigate to="dashboard" replace />} />
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="project/:projectId">
        <Route path="customer-uploads" element={<CustomerUploads />} />
        <Route path="furdexo-uploads" element={<FurdexoUploads />} />
      </Route>
      <Route path="customer">
        <Route path=":customerId">
          <Route path="" element={<CustomerInfo />} />
          <Route path="project/:projectId">
            <Route path="orderdetails" element={<OrderDetails />} />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="/">
      <Route path="" element={<Navigate to="login" replace />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<CustomerRegistration />} />
    </Route>
    <Route path="customer" element={<Layout />}>
      {/* <Route path=":customerId" element={<CustomerInfo />} /> */}
      <Route path="register" element={<CustomerRegistration />} />
      <Route path="profile" element={<ProfileSettings />} />
      <Route path="enquiries" element={<CustomerEnquiry />} />
      <Route path="project/:projectId">
        <Route path="" element={<ProjectDetails />} />
        <Route path="furdexo-uploads" element={<ViewFurdexoUploads />} />
        <Route path="v1" element={<ProjectDetailsV1 />} />
        <Route
          path=":spaceId/drawings"
          element={<DrawingViewer />}
        />
      </Route>
    </Route>
  </>
);