// furdexo-app-frontend/src/action/projectActions.js
import axios from "axios";
import { urlProject, urlProjects } from "../config/url";
import { toast } from "react-toastify";

export async function projectRegistration(dispatch, payload) {
  try {
    const response = await axios.post(urlProjects, payload);
    dispatch({ type: "PROJECT_REGISTER_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({
      type: "PROJECT_ERROR",
      apiResponse: error.response.data,
    });
    console.log(error);
  }
}

export async function getProjects(dispatch) {
  try {
    const response = await axios.get(urlProjects);
    dispatch({
      type: "PROJECTS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    toast.error(error.response.data.message, {
      position: "bottom-center",
    });
    dispatch({
      type: "PROJECT_ERROR",
      apiResponse: error.response.data,
    });
  }
}

export async function getProjectsById(dispatch, projectId) {
  try {
    const response = await axios.get(`${urlProject}/${projectId}`);
    dispatch({
      type: "PROJECT_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "PROJECT_ERROR",
      apiResponse: error.response?.data,
    });
  }
}

export async function getProjectsByCustomerId(dispatch, customerId) {
  try {
    const response = await axios.get(`${urlProjects}/${customerId}`);
    dispatch({
      type: "PROJECTS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "PROJECT_ERROR",
      apiResponse: error.response?.data,
    });
  }
}

export async function updateProjectsByProjectId(dispatch, projectId, data) {
  try {
    const response = await axios.put(`${urlProject}/${projectId}`, data);
    dispatch({
      type: "PROJECT_UPADTE_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({
      type: "PROJECT_ERROR",
      apiResponse: error.response?.data,
    });
  }
}
