// client/src/components/OrderHistory.js


import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';

const OrderHistory = ({ project, onClose }) => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        setLoading(true);
        // Note: We need the customerId from the URL or parent component
        const customerId = window.location.pathname.split('/')[2]; // This is a temporary solution
        const response = await fetch(
          `http://localhost:5000/api/customers/${customerId}/projects/${project._id}/order-history`
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch order history');
        }
        
        const data = await response.json();
        console.log('Order History Data:', data); // Debug log
        setOrderHistory(data.orderHistory || []);
      } catch (err) {
        console.error('Error fetching order history:', err);
      } finally {
        setLoading(false);
      }
    };

    if (project?._id) {
      fetchOrderHistory();
    }
  }, [project]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center">
        <div className="bg-white rounded-lg p-6">
          <div className="text-gray-600">Loading order history...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-start justify-center pt-16">
      <div className="bg-white rounded-lg w-[420px]">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b">
          <div className="text-base font-medium">
            Order History for {project?.name || 'Project'}
          </div>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-4 w-4" />
          </button>
        </div>

        {/* Status section */}
        <div className="px-6 pb-6">
          <div className="text-sm text-gray-600 mb-4">Status</div>
          
          {/* Timeline */}
          <div className="relative pl-6 space-y-6">
            {/* Vertical Dotted Line */}
            <div className="absolute left-[11px] top-[24px] bottom-6 border-l-2 border-dotted border-gray-300"></div>

            {orderHistory.length > 0 ? (
              orderHistory.map((history, index) => (
                <div key={index} className="relative flex items-start gap-4">
                  {/* Timeline Dot */}
                  <div 
                    className={`absolute -left-[6px] mt-[6px] w-[14px] h-[14px] rounded-full border-[3px] border-white 
                      ${index === 0 ? 'bg-blue-600' : 'bg-gray-400'}`}
                    style={{ boxShadow: '0 0 0 2px rgb(229 231 235)' }}
                  />

                  {/* Content */}
                  <div className="min-w-0">
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium text-gray-900">
                        {history.status}
                      </span>
                    </div>
                    {history.details && (
                      <div className="text-sm text-gray-600 mt-1">
                        {history.details}
                      </div>
                    )}
                    {history.date && (
                      <div className="text-xs text-gray-500 mt-1">
                        {formatDate(history.date)}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-4">
                No order history available.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;