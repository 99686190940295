// furdexo-app-frontend/src/store/store.js

import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "../reducers/userReducer";
import { projectReducer } from "../reducers/projectReducer";
import { spaceReducer } from "../reducers/spaceReducer";
import { chatBotReducer } from "../reducers/chatBotReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    space: spaceReducer,
    chatbot: chatBotReducer
  },
});

export default store;

