// furdexo-app-frontend/src/pages/customer/CustomerRegistration.js
import React, { useState, useEffect } from "react";
import { userRegistration } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CustomerRegistration = () => {
  const dispatch = useDispatch();
  const { apiLoading, apiResponse, apiSuccess } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    websiteLink: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    email: "",
    password: "",
    whatsappNo: "",
    termsAccepted: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    await userRegistration(dispatch, { ...formData });
  };
  useEffect(() => {
    if (!apiLoading && apiSuccess) {
      navigate(`/login`);
    }
  }, [navigate, apiLoading]);

  return (
    <div className="min-h-screen bg-white p-6">
      <div className="max-w-4xl mx-auto">
        {/* Registration Form */}
        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <h2 className="text-xl font-medium mb-6">ENQUIRY STEP: Register</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Company Info Section */}
            <div>
              <h3 className="text-lg font-medium mb-4">Company Info</h3>
              <div className="space-y-4">
                <div>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="websiteLink"
                    placeholder="Website Link"
                    value={formData.websiteLink}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
              </div>
            </div>

            {/* Your Profile Section */}
            <div>
              <h3 className="text-lg font-medium mb-4">Your Profile</h3>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />
                </div>
                <div>
                  <div className="flex">
                    <div className="bg-gray-50 border border-r-0 border-gray-200 rounded-l-md px-3 py-2 text-gray-500">
                      +91
                    </div>
                    <input
                      type="tel"
                      name="whatsappNo"
                      placeholder="Whatsapp No."
                      value={formData.whatsappNo}
                      onChange={handleInputChange}
                      className="flex-1 px-4 py-2 bg-gray-50 border border-gray-200 rounded-r-md"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="termsAccepted"
                id="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleInputChange}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label htmlFor="termsAccepted" className="text-sm">
                I agree to the Terms of Service. Read them{" "}
                <button type="button" className="text-gray-900 underline">
                  here
                </button>
              </label>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800 transition-colors"
              >
                Register & Share Equiry
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerRegistration;
