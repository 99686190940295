import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

// Ensure PDF.js uses the right worker
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs";//`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({
  pdfUrl,
  pageNumber,
  setPageNumber,
  numPages,
  setNumPages,
}) => {

  // Function to handle document load
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Document
        file={pdfUrl}
        onLoadSuccess={onLoadSuccess}
        loading="Loading PDF..."
        renderMode="svg"
      >
        <Page pageNumber={pageNumber} />
      </Document>

      {/* Pagination Controls */}
      {/* <div>
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Prev
        </button>
        <span>
          {pageNumber} of {numPages}
        </span>
        <button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default PdfViewer;
