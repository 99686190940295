import axios from "axios";
import { API_BASE_URL } from "../config/url";

axios.interceptors.request.use(
  (config) => {
    config.baseURL = API_BASE_URL;
    const token = window.localStorage.getItem("token") || "";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
axios.interceptors.response.use(console.log("axios.interceptors.response"));
