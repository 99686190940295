// furdexo-app-frontend/src/pages/customer/DrawingViewer.js
import React, { useEffect, useState, useRef } from "react";
import {
  X,
  Upload,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  Plus,
  ArrowLeft,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDrawingPreviewBySpaceIdByDrawingId,
  getSpaceById,
  updateDrawingBySpaceIdByDrawingId,
} from "../../actions/spaceActions";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, urlProjects } from "../../config/url";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import PdfViewer from "../../components/PDFReader/PdfViewer";

const DrawingThumbnail = ({ drawingId, name, isSelected, onClick }) => {
  console.log(
    "=====drawingId, name, isSelected, onClick",
    drawingId,
    name,
    isSelected,
    onClick
  );
  const { projectId, spaceId } = useParams();
  const [pdfPreview, setPdfPreview] = useState(null);
  const handleGetPreviewDrawing = async () => {
    try {
      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${spaceId}/drawings/${drawingId}`
      );
      const blob = await response.blob();
      setPdfPreview(URL.createObjectURL(blob));
    } catch (error) {
      console.error("Error generating preview:", error);
    }
  };

  useEffect(() => {
    handleGetPreviewDrawing();
  }, [drawingId]);
  return (
    <div
      onClick={onClick}
      className={`relative rounded-lg overflow-hidden cursor-pointer border-2 
      ${isSelected ? "border-gray-700" : "border-gray-200"}`}
    >
      {/* <div className="aspect-square bg-white">
        <div className="w-full h-full flex items-center justify-center">
          <div className="border-2 border-dashed border-gray-300 rounded p-2">
            {pdfPreview && (
              <img src={pdfPreview} alt="PDF preview" className="w-full" />
            )}
          </div>
        </div>
      </div> */}
      <div
        className={`p-2 text-center ${
          isSelected ? "bg-gray-700 text-white" : "bg-gray-200"
        }`}
      >
        {name}
      </div>
    </div>
  );
};

const DrawingUpdate = ({ isOpen, onClose, drawing, onUpdate }) => {
  const dispatch = useDispatch();
  const { projectId, spaceId } = useParams();
  const [newName, setNewName] = useState(drawing?.name || "");
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const [version, setVersion] = useState(parseInt(drawing.version));

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        setError("Please upload a PDF file");
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      if (selectedFile) {
        formData.append("drawings", selectedFile);
      }
      formData.append("drawingId", drawing._id);
      formData.append("originalName", newName);
      formData.append("version", version);

      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${spaceId}/drawings/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      // } else {
      //   await updateDrawingBySpaceIdByDrawingId(
      //     dispatch,
      //     projectId,
      //     spaceId,
      //     drawing._id,
      //     {
      //       originalName: newName,
      //       version,
      //     }
      //   );
      // }
      setIsSubmitting(false);
      toast.success("Drawing Updated Successfully", {
        position: "bottom-center",
      });
    } catch (error) {
      console.error("Upload error:", error);
      // Handle error
    } finally {
      setIsSubmitting(false);
      onClose(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />

      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-xl">
        <div className="bg-white rounded-lg shadow-lg">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-lg font-medium">
              Update {drawing.originalName}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="grid grid-cols-2 gap-2">
              {/* Preview Section */}
              <div className="col-span-1 bg-gray-100 rounded-lg p-4">
                <div className="aspect-square relative">
                  <div className="absolute top-2 right-2 bg-gray-700 text-white text-sm px-2 py-1 rounded">
                    v{version}
                  </div>
                  <div className="h-full flex items-center justify-center border-2 border-dashed border-gray-300">
                    <div className="text-center">
                      <div className="font-medium mb-2 text-word-break">
                        {drawing.originalName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500 mt-4">
                  <div className="flex items-start gap-2">
                    <span>ℹ️</span>
                    <p>
                      For detecting names and version, kindly upload files in
                      the following format
                    </p>
                  </div>
                  <div className="ml-6 mt-1 font-mono text-word-break">
                    DrawingName - {drawing.originalName}
                  </div>
                </div>
              </div>

              {/* Form Section */}
              <div className="space-y-4">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept=".pdf"
                  className="hidden"
                />

                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="w-full px-2 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 flex items-center justify-center gap-1"
                >
                  <Upload size={18} />
                  Upload Updated Drawing (.pdf)
                </button>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Rename as
                  </label>
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Enter file name"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Change Version
                  </label>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => setVersion((v) => Math.max(1, v - 1))}
                      className="px-3 py-1 border rounded hover:bg-gray-50"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={version}
                      onChange={(e) => setVersion(parseInt(e.target.value))}
                      className="w-20 p-2 border rounded text-center"
                      min="1"
                    />
                    <button
                      onClick={() => setVersion((v) => v + 1)}
                      className="px-3 py-1 border rounded hover:bg-gray-50"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="p-4 border-t">
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800 
              disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? "Saving..." : "Save Changes"}
            </button>
            {/* <button
               className="w-full bg-gray-700 text-white py-2 rounded hover:bg-gray-800"
               onClick={onClose}
             >
               Save Changes
             </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const DrawingViewer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { projectId, spaceId } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [updateDrawingModel, setUpdateDrawingModel] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(0);
  const { space, previewDrawing, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );
  const [drawings, setDrawings] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPreview, setPdfPreview] = useState({});

  const handleGetSpace = async () => {
    await getSpaceById(dispatch, projectId, spaceId);
  };
  useEffect(() => {
    handleGetSpace();
  }, [dispatch, projectId, spaceId]);

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("drawings", file);
      });

      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${spaceId}/drawings/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      toast.success(data?.message, { position: "bottom-center" });
      setDrawings(data.results);
    } catch (error) {
      console.error("Upload error:", error);
      // Handle error
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (space?.uploads) setDrawings(space?.uploads.filter((upload) => upload?.uploadedBy !== "furdexo"));
  }, [space]);

  const downloadPdf = (pdfUrl, downloadName) => {
    if (!pdfUrl) {
      toast.error("Please select drawing to download");
      return;
    }
    saveAs(pdfUrl, downloadName);
  };

  // useEffect(() => {
  //   setPdfPreview((prev) => ({
  //     ...prev,
  //     [previewDrawing.drawingId]: previewDrawing.,
  //   }));
  // }, [previewDrawing]);
  console.log("=====drawings", pdfPreview, drawings);
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Main Content */}
      <main className="max-w-7xl mx-auto p-4">
        {/* Drawing Navigation */}
        <div className="flex items-start gap-4 mb-4">
          <div className="font-medium">{space?.name}</div>
          <div className="flex-1 flex justify-end gap-2">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              accept=".pdf,.dwg,.dxf"
              className="hidden"
            />
            <button
              disabled={isUploading}
              onClick={() => fileInputRef.current?.click()}
              className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 flex items-center gap-2"
            >
              <Plus size={18} />
              {isUploading ? "Uploading..." : "Add New Drawing"}
            </button>
          </div>
        </div>
        <div className="flex items-start gap-4 mb-4">
          <div className="flex gap-2">
            {drawings.length > 0 &&
              drawings.map((drawing, index) => (
                <DrawingThumbnail
                  key={drawing._id}
                  drawingId={drawing._id}
                  name={drawing.originalName}
                  isSelected={selectedDrawing === index}
                  onClick={() => setSelectedDrawing(index)}
                  pdfPreview={pdfPreview}
                />
              ))}
          </div>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <div
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            {`${API_BASE_URL}/${drawings[selectedDrawing]?.path}` ? (
              <a
                href={`${API_BASE_URL}/${drawings[selectedDrawing]?.path}`}
                target="_blank"
              >
                View PDF
              </a>
            ) : (
              <span
                onClick={() => toast.error("Please select drawing to download")}
              >
                View PDF
              </span>
            )}
          </div>
          <div
            onClick={() =>
              downloadPdf(
                `${API_BASE_URL}/${drawings[selectedDrawing]?.path}`,
                `${drawings[selectedDrawing].originalName}`
              )
            }
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            Download PDF
          </div>
        </div>
        {/* Drawing Viewer */}
        <div className="relative bg-white rounded-lg shadow">
          <div className="aspect-[16/9] flex items-center justify-center">
            {drawings[selectedDrawing] && (
              <PdfViewer
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                numPages={numPages}
                setNumPages={setNumPages}
                pdfUrl={`${API_BASE_URL}/${drawings[selectedDrawing]?.path}`}
              />
            )}
          </div>

          {/* Navigation Controls */}
          <div
            className="absolute inset-x-0 bottom-1/2 flex justify-between px-4 transform translate-y-1/2"
            style={{ zIndex: 50 }}
          >
            <button
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-300"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-300"
            >
              <ChevronRight size={24} />
            </button>
          </div>

          {/* Actions */}
          <div className="absolute bottom-4 right-4 flex gap-2">
            <button
              onClick={() => setUpdateDrawingModel(true)}
              className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
            >
              Update Drawing
            </button>
            {/* <button
              onClick={() => navigate(`/customer/project/${projectId}`)}
              className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 flex items-center gap-2"
            >
              <ArrowLeft size={18} />
              Back
            </button> */}
          </div>
        </div>
      </main>
      {drawings.length > 0 && (
        <DrawingUpdate
          isOpen={updateDrawingModel}
          onClose={() => setUpdateDrawingModel(false)}
          drawing={drawings[selectedDrawing]}
        />
      )}
    </div>
  );
};

export default DrawingViewer;
