import React from "react";
import "./Processing.scss";
// A simple processing component
const Processing = (props) => {
  if (!props.show) return null;
  return (
    <div className="processing-overlay">
      <h2>Processing...</h2>
      <div className="spinner"></div>
    </div>
  );
};

export default Processing;
