// src/pages/Admin/CustomerUploads.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { getSpaces } from "../../actions/spaceActions";
import { API_BASE_URL } from "../../config/url";
import { toast } from "react-toastify";

const DrawingThumbnail = ({ drawing, setDrawingSelected, drawingSelected }) => {
  // console.log("====drawing", drawing, drawingSelected);
  return (
    <div
      onClick={() =>
        setDrawingSelected({
          path: `${API_BASE_URL}/${drawing.path}`,
          originalName: drawing.originalName,
          _id: drawing._id,
        })
      }
      className={`relative rounded-lg overflow-hidden cursor-pointer border-2 
      ${
        drawing._id === drawingSelected._id
          ? "border-gray-700"
          : "border-gray-200"
      }`}
    >
      <div
        className={`p-2 text-center ${
          drawing._id === drawingSelected._id
            ? "bg-gray-700 text-white"
            : "bg-gray-200"
        }`}
      >
        {drawing?.originalName}
      </div>
    </div>
  );
};

const CustomerUploads = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [drawingSelected, setDrawingSelected] = useState({
    path: "",
    originalName: "",
    _id: ""
  });
  const { spaces, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );

  const handleGetSpace = async () => {
    await getSpaces(dispatch, projectId);
  };

  useEffect(() => {
    handleGetSpace();
  }, [projectId]);

  const downloadPdf = (pdfUrl, downloadName) => {
    if (!pdfUrl) {
      toast.error("Please select drawing to download");
      return;
    }
    saveAs(pdfUrl, downloadName);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="h-full flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold mb-6">Customer Uploads</h1>
        </div>
        <div className="h-full flex items-center">
          <div
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            {`${drawingSelected?.path}` ? (
              <a href={`${drawingSelected?.path}`} target="_blank">
                View PDF
              </a>
            ) : (
              <span
                onClick={() =>
                  toast.error("Please select drawing to download")
                }
              >
                View PDF
              </span>
            )}
          </div>
          <div
            onClick={() =>
              downloadPdf(
                `${drawingSelected?.path}`,
                `${drawingSelected?.originalName}`
              )
            }
            className={`p-2 text-center bg-gray-700 text-white relative rounded-lg overflow-hidden cursor-pointer border-2 border-gray-200`}
          >
            Download PDF
          </div>
        </div>
      </div>
      {spaces.length > 0 ? (
        <div className="space-y-4">
          {spaces.map((space) => (
            <div key={space._id} className="bg-white shadow-sm p-4 rounded-lg">
              <div className="flex items-start gap-4 mb-4">
                <div className="font-medium">{space?.name}</div>
              </div>
              <div className="flex items-start gap-4 mb-4">
                <div className="flex gap-2">
                  {space.uploads?.length > 0 &&
                    space.uploads
                      ?.filter((drawing) => drawing?.uploadedBy !== "furdexo")
                      .map((drawing, index) => (
                        <DrawingThumbnail
                          key={drawing._id}
                          drawing={drawing}
                          name={drawing.originalName}
                          setDrawingSelected={setDrawingSelected}
                          drawingSelected={drawingSelected}
                        />
                      ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No customer uploads found.</div>
      )}
    </div>
  );
};

export default CustomerUploads;
