const initialState = {
  chatBots: [],
  chatBot: {},
  apiResponse: null,
  apiLoading: true,
  apiSuccess: true,
};

export const chatBotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHATBOTS_FETCH_SUCCESS":
      return { ...state, chatBots: action.apiResponse, loading: false };
    case "CHATBOTS_REGISTER_SUCCESS":
      return {
        ...state,
        chatBot: action.apiResponse,
        loading: false,
        error: null,
      };
    case "CHATBOTS_ERROR":
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
