import React, { useEffect, useState } from "react";
import {
  MessageCircle,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Tag,
  X,
} from "lucide-react";
import {
  chatBotRegistration,
  getChatBotByProjectId,
} from "../../actions/chatBotActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ChatBot = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdminInUrl =
    location.pathname.includes("admin") || location.search.includes("admin");
  const [message, setMessage] = useState();
  const { chatBots, apiLoading, apiSuccess } = useSelector(
    (state) => state.chatbot
  );
  const handleChatBotByProjectId = async () => {
    await getChatBotByProjectId(dispatch, props.selectedProject._id);
  };
  const handleRegisterChatBot = async () => {
    await chatBotRegistration(dispatch, {
      projectId: props.selectedProject._id,
      senderType: isAdminInUrl ? "admin" : "customer",
      senderId: "111111111",
      message,
    });
    setMessage("");
    handleChatBotByProjectId();
  };
  useEffect(() => {
    if (props.selectedProject?._id) handleChatBotByProjectId();
  }, [props.selectedProject, message]);
  console.log(
    "chatBots, apiLoading, apiSuccess",
    chatBots,
    apiLoading,
    apiSuccess
  );
  return (
    <>
      {props.isChatOpen ? (
        <div className="fixed top-0 right-0 w-96 h-full bg-white shadow-lg flex flex-col">
          <div className="p-4 border-b">
            <div className="flex justify-between items-center mb-1">
              <h3 className="font-semibold">Chat</h3>
              <div className="flex items-center gap-4">
                <div className="text-sm text-gray-500">
                  CURRENT FOCUS {props.selectedProject.projectName}
                </div>
                <button onClick={() => props.setIsChatOpen(false)}>
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 overflow-y-auto">
            <div className="space-y-4">
              {chatBots &&
                chatBots.map((chatBot) => (
                  <>
                    {isAdminInUrl ? (
                      chatBot.senderType === "admin" ? (
                        <>
                          <div className="bg-gray-100 p-3 rounded-lg max-w-[80%] ml-auto">
                            {chatBot.message}
                          </div>
                          <div
                            className="text-sm text-gray-600 ml-2"
                            style={{ textAlign: "right" }}
                          >
                            Furdexo
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-gray-700 text-white p-3 rounded-lg max-w-[80%]">
                            {chatBot.message}
                          </div>
                          <div
                            className="text-sm text-gray-600 ml-2"
                            style={{ textAlign: "left" }}
                          >
                            Customer
                          </div>
                        </>
                      )
                    ) : chatBot.senderType === "admin" ? (
                      <>
                        <div className="bg-gray-700 text-white p-3 rounded-lg max-w-[80%]">
                          {chatBot.message}
                        </div>
                        <div
                          className="text-sm text-gray-600 ml-2"
                          style={{ textAlign: "left" }}
                        >
                          Furdexo
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="bg-gray-100 p-3 rounded-lg max-w-[80%] ml-auto">
                          {chatBot.message}
                        </div>
                        <div
                          className="text-sm text-gray-600 ml-2"
                          style={{ textAlign: "right" }}
                        >
                          Customer
                        </div>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="p-4 border-t">
            <div className="relative">
              <input
                type="text"
                placeholder="Type your message ..."
                className="w-full p-2 pr-8 border rounded"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleRegisterChatBot();
                }}
              />
              <button
                onClick={handleRegisterChatBot}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                <ChevronUp className="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed bottom-6 right-6">
          <button
            className="bg-gray-700 p-2 rounded-full text-white"
            onClick={() => props.setIsChatOpen(true)}
          >
            <MessageCircle className="w-6 h-6" />
          </button>
        </div>
      )}
    </>
  );
};

export default ChatBot;
