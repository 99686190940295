import axios from "axios";
import { urlChatBots } from "../config/url";

export async function chatBotRegistration(dispatch, payload) {
  try {    
    const response = await axios.post(urlChatBots, payload);
    dispatch({ type: "CHATBOTS_REGISTER_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({
      type: "CHATBOTS_ERROR",
      apiResponse: error.response.data,
    });
    console.log(error);
  }
}


export async function getChatBotByProjectId(dispatch, projectId) {
  try {
    const response = await axios.get(`${urlChatBots}/${projectId}`);
    dispatch({
      type: "CHATBOTS_FETCH_SUCCESS",
      apiResponse: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: "CHATBOTS_ERROR",
      apiResponse: error.response.data,
    });
  }
};
